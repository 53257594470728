.postsBox {
  border: #333 solid 1px;
  height: 120px;
  width: 720px;
  position: relative;
  background-color: #fff;
}

.postsTitle {
  line-height: 40px;
  padding-left: 6px;
  position: absolute;
  top: 0;
  left: 120px;
  width: 600px;
  vertical-align: middle;
}

.postsTitle h3 {
  font-size: 18px;
  font-weight: bold;
}

.postsBrief {
  height: 60px;
  width: 600px;
  position: absolute;
  top: 40px;
  left: 120px;
  overflow: hidden;
}
.postsBrief p { padding-left: 6px; }

.postsMeta {
  height: 20px;
  width: 600px;
  position: absolute;
  top: 100px;
  left: 120px;
  font-size: 11px;
  line-height: 20px;
  vertical-align: middle;
}